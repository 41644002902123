<template>
  <div class="intro-page">
    <!--    container-->
    <!-- <section id="introduction_1" class="introduction"> -->
      <!-- <div class="introduction_1_content">
        <img src="./../../assets/images/home/title1_bg.png" alt="紫色光晕" class="introduction_title1_bg">
        <div class="section-left"> -->
          <!--          欢迎标语-->
          <!-- <div class="slogan"></div>
          <div class="down_btns" v-show="true">
            <div class="down_btn_group">
              <img class="down_btn android_btn"
                   @mouseover="downCode.android = false"
                   @mouseleave="downCode.android = false"
                   src="./../../assets/images/home/ds_download.png" alt="安卓">
              <img v-if="downCode.android" class="down_code android_code" src="./../../assets/images/home/andorid.png"
                   alt="安卓二维码">
            </div> -->
            <!-- <div class="down_btn_group">
              <img class="down_btn ios_btn"
                   @mouseover="downCode.ios = false"
                   @mouseleave="downCode.ios = false"
                   src="./../../assets/images/home/ios_btn.png" alt="ios">
              <img v-if="downCode.ios" class="down_code ios_code" src="./../../assets/images/home/iOS.png"
                   alt="ios二维码">
            </div> -->
          <!-- </div>
        </div> -->
        <!-- <div class="mobile">
          <img class="mobile-bg" src="./../../assets/images/home/mobile.png" alt="">
          <div style="overflow: hidden" class="swiper mobile-pic-content swiper-no-swiping" id="mobile">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in phoneList" :key="index">
                <img class="mobile-pic" :src="item" :alt="`手机内幻灯片${index}`">
              </div>
            </div>
          </div> -->
          <!--          放大镜-->
          <!-- <div class="front-gradient"></div>
        </div> -->
      <!-- </div>
    </section> -->
    <section id="introduction_4" class="introduction section-content">
      <div class="introduction_4_section">
        <!--      <div class="introduction introduction_4_section section-content">-->
        <!-- <div>
          <div class="introduction_4_title"></div>
          <div class="row section-content">
            <div class="introduction_4_pc"></div>
          </div>

        </div> -->
        <div>
          <!-- <div class="introduction_4_des1">《剧阵》剧本投稿须知</div> -->
          <h5 class="introduction_4_des2">联系我们</h5>
          <br>
          <div class="introduction_4_des3">
            <p>客服邮箱：kefu@jzjbs.com</p>
            <br>
            <p>账户封禁申诉渠道：kefu@jzjbs.com</p>
            <br>
            <p>商务合作：kefu@jzjbs.com</p>
          </div>
          <h5 class="introduction_4_des2">关注公众号</h5>
          <img src="./../assets/images/home/code2.jpg" class="introduction_4_code" alt="公众号">
        </div>
      </div>
      <footer>
        <div class="footer">
          <p></p>
          <p>版权所有:Copyright️2023北京多身科技有限公司</p>
          <!-- <p>
            地址：北京市朝阳区利泽中一路1号院1号楼2层202B号北795｜ 网络文化经营许可证：京网文【2018】8935-773号｜增值电信业务经营许可证：京B2-20182020
          </p> -->
          <p>
            地址：北京市朝阳区广顺北大街33号院1号楼（五、十、十一层）1单元6790号
          </p>
		  <!-- 
        <p>
            地址：北京市朝阳区利泽中一路1号院1号楼2层202B号北795｜ 网络文化经营许可证：京网文【2018】8935-773号｜增值电信业务经营许可证：京B2-20182020
          </p><a href="http://www.beian.gov.cn/portal/recordQuery">公安备案号：11010502045358</a> -->
		  <a style="padding-left:20px; color: #999999;" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2022021045号-1</a>
        </div>
      </footer>
    </section>

  </div>
</template>

<script>
// import phone1 from './../../assets/images/home/phone1.png'
// import phone2 from './../../assets/images/home/phone2.png'
// import phone3 from './../../assets/images/home/phone3.png'
// import phone4 from './../../assets/images/home/phone4.png'
// import swiper1 from './../../assets/images/swipper/1.png'
// import swiper2 from './../../assets/images/swipper/2.png'
// import swiper3 from './../../assets/images/swipper/3.png'
// import swiper4 from './../../assets/images/swipper/4.png'
// import swiper5 from './../../assets/images/swipper/5.png'
import './../components/ProductIntroduction/style.css';
// import './media.css';

export default {
  name: "Abouts",
  data() {
    return {
      // activeSlide: 0,
      // phoneList: [
      //   phone1,
      //   phone2,
      //   phone3,
      //   // phone4,
      // ],
      // swiperList: [
      //   swiper1,
      //   swiper2,
      //   swiper3,
      //   swiper4,
      //   swiper5,
      // ],
      // downCode: {
      //   android: false,
      //   ios: false,
      // }
    }
  },
  mounted() {
    this.getPath();
    this.$nextTick(() => {
      const that = this
      new window.Swiper('#mobile', {
        loop: true,
        speed: 300,
        autoplay: {
          delay: 3000
        },
      })
      new Swiper('#gallery', {
        effect: 'coverflow',
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,//slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: 100,//每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 150,//slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 1,//depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
          slideShadows: false,//开启slide阴影。默认 true。
        },
        on: {
          slideChange () {
            that.activeSlide = this.realIndex;
          }
        }
      })
      // new window.Swiper('#certify .swiper-container', {
      //   watchSlidesProgress: true,
      //   slidesPerView: 'auto',
      //   centeredSlides: true,
      //   loop: true,
      //   loopedSlides: 5,
      //   autoplay: true,
      //   on: {
      //     progress: function () {
      //       for (var i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i);
      //         var slideProgress = this.slides[i].progress;
      //         var modify = 1;
      //         if (Math.abs(slideProgress) > 1) {
      //           modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
      //         }
      //         var translate = slideProgress * modify * 260 + 'px';
      //         var scale = 1 - Math.abs(slideProgress) / 5;
      //         var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
      //         slide.transform('translateX(' + translate + ') scale(' + scale + ')');
      //         slide.css('zIndex', zIndex);
      //         slide.css('opacity', 1);
      //         if (Math.abs(slideProgress) > 3) {
      //           slide.css('opacity', 0);
      //         }
      //       }
      //     },
      //     setTransition: function (transition) {
      //       for (var i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i)
      //         slide.transition(transition);
      //       }
      //
      //     }
      //   }
      //
      // })
    })

  },
  watch: {
    '$route': 'getPath'
  },
  methods: {
    goAnchor() {
      let anchor = this.$el.querySelector('#introduction_4')
      document.documentElement.scrollTop = anchor.offsetTop
    },
    getPath() {
      const anchor = this.$route.query.anchor
      if (anchor) {
        this.$nextTick(() => {
          this.goAnchor()
        })
      } else {
        document.documentElement.scrollTop = 0
      }
    }
  }
}
</script>

<style scoped>
/* 上下浮动动画 start*/
.controller {
  position: absolute;
  width: 24.583vw;
  height: 32.25vw;
  top: -2.5vw;
  z-index: 100;
  left: 40%;
  -webkit-animation: bounce-down 1.6s linear infinite;
  animation: bounce-down 1.6s linear infinite;
}

.sally {
  position: absolute;
  top: 0;
}

@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-4px);
  }
  50%, 100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(4px);
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(-0.333vw);
  }
  50%, 100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0.333vw);
  }
}

/* 上下浮动动画 end*/

/*  放大镜 转动 start */
@keyframes spin2 {
  from {
    transform: translate(50%, 12.5vw) rotate(0turn) translate(-50%, -12.5vw) translate(50%, 50%) rotate(1turn) translate(-50%, -50%)
  }
  to {
    transform: translate(50%, 12.5vw) rotate(1turn) translate(-50%, -12.5vw) translate(50%, 50%) rotate(0turn) translate(-50%, -50%)
  }
}

.front-gradient {
  animation: spin2 5s infinite linear;
  z-index: 10;
}

/*  放大镜 转动 end */

/* 小火煎 start */
.introduction_2_right {
  animation: bounce-down 2s infinite linear;
}

/* 小火煎 edn */

/* 摇一摇手机start */
.introduction_4_pc {
  -webkit-animation: bounce-left-Right 1.6s linear infinite;
  animation: bounce-left-Right 1.6s linear infinite;
}

@-webkit-keyframes bounce-left-Right {
  25% {
    -webkit-transform: translate(-0.333vw, 0.333vw);
  }
  50%, 100% {
    -webkit-transform: translateX(0);
  }
  75% {
    -webkit-transform: translate(0.333vw, -0.333vw);
  }
}

/* 摇一摇手机end */
</style>
