<template>
  <div class="home">
    <nav class="navbar">
      <div class="navbar-brand">
        <img src="./../assets/logo_duoshen.png" alt="">
        <b style="font-size: 20px; color: white; font-family: 'YouSheBiaoTiHei';">多身星球</b>
      </div>
      <ul class="nav justify-content-end">
        <li class="nav-item navbar-right">
          <img v-show="active === '/'" alt="">
          <router-link class="nav-link"
                       to="/"
                       :class="{'active': active === '/'}">主页
          </router-link>
        </li>
        <li class="nav-item navbar-right">
          <img v-show="active === '/about'" alt="">
          <router-link class="nav-link"
                       to="/about"
                       :class="{'active': active === '/about'}">关于我们
          </router-link>
        </li> 
      </ul>
    </nav>
    <div>
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  watch: {
    '$route': 'getPath'
  },
  data() {
    return {
      active: '/',
    }
  },
  mounted() {
    this.getPath();
  },
  methods: {
    getPath() {
      const anchor = this.$route.query.anchor
      if (anchor) {
        this.active = anchor;
      } else {
        this.active = this.$route.path;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.home {
    margin: 0;
    padding: 0;
    min-height: 100%;
    max-height: 100vh;
}


.navbar {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  height: 70px;
  /*max-height: 72px;*/
}

.navbar-brand {
  margin-left: 40px;
}

.navbar-brand img:nth-of-type(1) {
  width: 50px;
  height: 50px;
  margin-right: 25px;
}


.nav {
  margin-right: 120px;
}

.nav-link {
  cursor: pointer;
  line-height: 70px;
  /*min-width: 75px;*/
  /*min-height: 26px;*/
  font-size: 16px;
  font-family: 'YouSheBiaoTiHei';
  color: #FFFFFF;
  letter-spacing: 0.26px;
  padding: 0 25px;

  /*line-height: 26px;*/
  /*padding: 16px 22px;*/
}

.active {
  /*font-size: 28px;*/
  font-size: 22px;
  letter-spacing: 0.36px;
  font-weight: 400;
}

.nav-item {
  position: relative;
}

.nav-item img {
  position: absolute;
  left: 0;
  top: 47%;
  z-index: -1;
  width: 98%;
}

</style>
