<template>
  <div class="recharge">
    <div class="recharge-card recharge-content">
      <div style="zoom: 0.65">
        <h5>请输入你的用户ID或手机号:</h5>
        <div class="row form">
          <label>
            <input type="number"
                   @input='userIdChange'
                   v-model="userId"
                   style="outline:none">
          </label>
          <div class="sure" @click="getUserName">
            确定
          </div>
          <div class="form-name" v-show="!!userNick">
            账号昵称:{{ userNick }}
          </div>
        </div>
        <h5>
          请选择充值金额
        </h5>
        <ul class="money-list">
          <li :class="{'money-active': payAmountActive === index}" v-for="(item,index) in moneyList" :key="index"
              @click="payAmountActive = index">
            <div class="money-header">
              <div class="money-header-icon">
                <img :src="item.image" alt="钻石图标">
              </div>
              <h5>X{{ item.diamondAmount }}</h5>
            </div>
            <div class="money-footer">￥{{ item.payAmount }}</div>
          </li>
        </ul>

        <h5>
          请选择支付方式
        </h5>

        <div class="row mode">
          <div class="mode_btn" @click="paymentMethod = 'alipay'">
            <img :src="paymentMethod === 'alipay' ? zfb2: zfb1" alt="支付宝">
          </div>
          <div class="mode_btn" @click="paymentMethod = 'wechat'">
            <img :src="paymentMethod === 'wechat' ? wechat2: wechat1" alt="微信">
          </div>
        </div>

        <div class="submitBtn" @click="paymentFn">
          确认支付
        </div>
      </div>
    </div>

    <div class="recharge-content">
      <Dialog :visible="payment" title="支付宝支付" class="payment" :onClose="()=>{payment=false}">
        <div class="payment-des">
          <p>支付完成前，请不要关闭此窗口。</p>
          <p>支付完成后，请根据您支付的情况点击按钮。</p>
        </div>
        <div class="payment-btns">
          <div class="payment-btn payment-refresh">刷新</div>
          <div class="payment-btn payment-down">完成</div>
        </div>
      </Dialog>

      <Dialog :visible="completePayment" title="支付完成" class="complete-payment" :onClose="()=>{completePayment=false}">
        <div class="complete-payment-icon">
          <img src="./../assets/images/recharge/Tick.png" alt="">
        </div>
        <p>请前往剧阵APP查询到账钻石</p>
      </Dialog>
      <Dialog :visible="wechatPayment" title="微信支付" class="wechat-payment complete-payment"
              :onClose="wechatPaymentClose">
        <div class="wechat-payment-card">
          <p>请使用微信扫一扫，扫描二维码支付</p>
          <p>本次支付<strong v-if="moneyList[payAmountActive]">{{ moneyList[payAmountActive].payAmount || 0 }}</strong>元</p>
          <div class="wechat-payment-icon">
            <img :src="wxPayQrUrl" alt="">
          </div>
        </div>
      </Dialog>
      <Dialog :visible="alipayPaymentModal" title="支付宝支付"
              id="alipayPaymentModal"
              class="wechat-payment complete-payment"
              :onClose="alipayPaymentClose">
        <div class="wechat-payment-card">
          <p>请使用支付宝扫一扫，扫描二维码支付</p>
          <p>本次支付<strong v-if="moneyList[payAmountActive]">{{ moneyList[payAmountActive].payAmount || 0 }}</strong>元</p>
          <iframe v-if="alipayPaymentModal" id="alipayFrame" width="200" height="200" scrolling="no"
                  frameborder="0"></iframe>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import {getUserNick, getPriceList, createOrder, getOrderStatus} from './../api/api'
import zfb1 from './../assets/images/recharge/zfb1.png'
import zfb2 from './../assets/images/recharge/zfb2.png'
import wechat1 from './../assets/images/recharge/wechat1.png'
import wechat2 from './../assets/images/recharge/wechat2.png'

let getOrderStatusTimer = null;

export default {
  name: "Recharge",
  components: {
    Dialog
  },
  data() {
    return {
      payment: false,
      completePayment: false,
      wechatPayment: false,
      alipayPaymentModal: false,
      userNick: '',
      userId: '',
      zfb1,
      zfb2,
      wechat1,
      wechat2,
      moneyList: [],
      payAmountActive: '',
      paymentMethod: '',
      wxPayQrUrl: '',
    }
  },
  methods: {
    // 支付
    paymentFn() {
      if (!this.check()) return;
      if (this.paymentMethod === 'alipay') {
        this.alipayPaymentFn();
      } else {
        this.wechatPaymentFn();
      }
    },

    // 支付宝支付
    async alipayPaymentFn() {
      this.alipayPaymentModal = true;
      const {tradeNo, alipayParam} = await this.createOrderFn();
      const alipayContainer = document.getElementById('alipayFrame');
      let dom = alipayContainer.contentWindow.document;
      dom.open();
      dom.write(alipayParam);
      dom.close();
      dom.contentEditable = true;
      dom.designMode = "on";
      if (getOrderStatusTimer) clearInterval(getOrderStatusTimer);
      getOrderStatusTimer = setInterval(async () => {
        let {data: {payStatus}} = await getOrderStatus(tradeNo);
        if (String(payStatus) === '1') {
          if (getOrderStatusTimer) clearInterval(getOrderStatusTimer);
          this.alipayPaymentModal = false;
          this.completePayment = true;
          this.tradeNo = '';
        }
      }, 3000)
    },
    // 微信支付
    async wechatPaymentFn() {
      const {tradeNo} = await this.createOrderFn();
      this.wechatPayment = true;
      if (getOrderStatusTimer) clearInterval(getOrderStatusTimer);
      getOrderStatusTimer = setInterval(async () => {
        let {data: {payStatus}} = await getOrderStatus(tradeNo);
        if (String(payStatus) === '1') {
          if (getOrderStatusTimer) clearInterval(getOrderStatusTimer);
          this.wechatPayment = false;
          this.completePayment = true;
          this.tradeNo = '';
        }
      }, 3000)
    },
    wechatPaymentClose() {
      this.wechatPayment = false;
      if (getOrderStatusTimer) clearInterval(getOrderStatusTimer);
    },
    alipayPaymentClose() {
      this.alipayPaymentModal = false;
      if (getOrderStatusTimer) clearInterval(getOrderStatusTimer);
    },
    // 创建订单
    async createOrderFn() {
      const order = await createOrder({
        "diamondPriceId": this.moneyList[this.payAmountActive].id,
        "paymethod": this.paymentMethod === 'alipay' ? 4 : 5,
        "userId": this.userId,
      });
      this.wxPayQrUrl = order.data.wxPayQrUrl;
      this.tradeNo = order.data.tradeNo;
      return order.data;
    },
    check() {
      let flag = true;
      let msg = '';
      if (!this.userId) {
        msg = '请输入你的用户ID或手机号';
        flag = false;
      } else if (!this.userNick) {
        msg = '请输入正确的用户ID';
        flag = false;
      } else if (!String(this.payAmountActive)) {
        msg = '请选择充值金额';
        flag = false;
      } else if (!this.paymentMethod) {
        msg = '请选择支付方式';
        flag = false;
      }
      if (!flag) {
        $('#myModalBody').html(msg);
        $('#myModal').modal();
      }
      return flag;
    },

    userIdChange(e) {
      const value = e.target.value;
      if (!value) {
        this.userNick = '';
      }
    },

    async getPriceListFn() {
      const loading = this.$Loading();
      try {
        const res = await getPriceList();
        this.moneyList = [...res.data]
      } catch (e) {
        console.log(e, 'error')
      } finally {
        loading.close();
      }
    },
    async getUserName() {
      if (!this.userId) {
        $('#myModalBody').html('请输入你的用户ID或手机号');
        $('#myModal').modal();
        return
      }
      try {
        const res = await getUserNick(this.userId);
        this.userNick = res.data
      } catch (e) {
        console.log(e, 'error')
      }
    }
  },
  mounted() {
    this.getPriceListFn();
  }
}
</script>

<style scoped>

.recharge {
  background-image: url("./../assets/images/bg.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  width: 100%;
  /*min-height: 750px;*/
  /*height: 100vh;*/
  overflow: hidden;
}

.recharge-card {
  width: 100%;
  overflow: hidden;
  padding-top: 94px;
  padding-left: 130px;
  /*padding-top: 10.917vw;*/
  /*padding-left: 6.5vw;*/
  /*;*/
}

h5 {
  font-size: 23px;
  font-family: YouSheBiaoTiHei;
  color: #FFFFFF;
  line-height: 3vw;
  margin-bottom: 1.417vw;
}

.form {
  display: flex;
  align-items: center;
  padding-bottom: 6.75vw;
  position: relative;
}

.form-name {
  font-size: 16px;
  font-weight: 400;
  color: #FBD402;
  line-height: 1.833vw;
  letter-spacing: 2px;
  margin-left: 4.833vw;
}

.form-name {
  position: absolute;
  bottom: 3.583vw;
}

input {
  width: 18.583vw;
  height: 4.583vw;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2.25vw;
  border: 1px solid #FFFFFF;
  margin-right: 2.417vw;
  text-align: center;
  font-size: 23px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 3.333vw;
  letter-spacing: 0.25vw;
}

.sure {
  width: 8vw;
  height: 4vw;
  background: #FBD402;
  border-radius: 2vw;
  font-weight: 400;
  color: #000000;
  line-height: 4vw;
  letter-spacing: 0.25vw;
  font-size: 1.917vw;
  text-align: center;
  cursor: pointer;
}

.money-list {
  margin-bottom: 3.583vw;
  display: flex;
}

.money-list li {
  margin-right: 3.083vw;
  width: 9.75vw;
  border-radius: 0.667vw;
  overflow: hidden;
  border: 2px solid transparent;
  cursor: pointer;
}

.money-active {
  border: 2px solid #FBD402 !important;
  background: rgba(255, 255, 255, 0.15);
}

.money-header {
  padding-top: 1.583vw;
  width: 100%;
  height: 9vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #999999;
}

.money-header-icon {
  margin-bottom: 0.75vw;
  /*min-width: 4.167vw;*/
  width: 4.5vw;
  height: 4.5VW;
}

.money-header-icon img {
  width: 100%;
}

.money-header h5 {
  font-size: 1.833vw;
  font-weight: 600;
  color: #FBD402;
  line-height: 2.5vw;
}

.money-footer {
  width: 100%;
  height: 3.5vw;
  background: #FFFFFF;
  opacity: 0.89;
  text-align: center;
  line-height: 3.5vw;
}

.mode {
  margin-bottom: 4vw;
}

.mode_btn {
  width: 17.667vw;
  height: 5.333vw;
  margin-right: 3.667vw;
  border-radius: 1.417vw;
  overflow: hidden;
  cursor: pointer;
}

.mode_btn img {
  width: 100%;
  height: 100%;
}

.submitBtn {
  width: 28.667vw;
  height: 5.583vw;
  background: #FBD402;
  border-radius: 4.167vw;
  text-align: center;
  line-height: 5.583vw;
  font-size: 2.25vw;
  font-weight: 600;
  margin-bottom: 6.083vw;
  cursor: pointer;
}

/*支付*/
.payment .payment-des {
  padding-top: 2.333vw;
  padding-bottom: 3.917vw;
}

.payment .payment-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.payment .payment-btn {
  width: 13.583vw;
  height: 4.583vw;
  line-height: 4.583vw;
  border-radius: 4.167vw;
  font-size: 1.917vw;
}

.payment-refresh {
  background: #D9D9D9;
  margin-right: 6.417vw;
}

.payment-down {
  background: #FBD402;
}

/*支付完成*/
.complete-payment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.complete-payment-icon {
  width: 11.25vw;
  height: 9.583vw;
  margin: 1.417vw auto;
}

.complete-payment-icon img {
  width: 100%;
  height: 100%;
}

.complete-payment p {
  font-size: 17px;
}

/*微信支付*/
.wechat-payment-card {
  padding: 0 5vw;
}

.wechat-payment p:nth-of-type(1) {
  margin-top: 2vw;
  margin-bottom: 1.333vw;
}

.wechat-payment p:nth-of-type(2) {
  margin-bottom: 2.333vw;
}

.wechat-payment strong {
  color: red;
}

.wechat-payment-icon {
  width: 17.75vw;
  height: 18.167vw;
  margin: 0 auto;
}

.wechat-payment-icon img {
  width: 100%;
  height: 100%;
}
</style>
